<template>
	<div class="mealCost">
		<div class="my-card topCard">
			<div class="top">
				<div class="left">
					<van-image width="40" height="40" radius='30%' :src="ProfilePicture" class="img" />
					<span style="margin-left: 10px;">{{ChildrenName}}</span>
				</div>
			</div>
			<!-- 中间月份 -->
			<div class="mouthInfo">
				<div class="calendarInfo">
					<div class="date" @click="openMouth">
						<span>{{year}}</span>
						<span class="line"></span>
						<span>{{mouthDay}}</span>
					</div>
					<!-- 月数据 -->
					<div class="mouthDay" style="margin-top: 30px;">
						<div class="box" v-for="(item,index) in mouthList" :key="item"
							:class="[currentIndex == index? 'active':'']" @click="tabsclick(index)">
							{{item + '月'}}
						</div>
					</div>
					<!-- 出勤天数 -->
					<div class="attendceDay">
						<div class="topDay">
							<div class="left">
								<p>{{AttendanceDay}}</p>
								<p>出勤天数</p>
							</div>
							<div class="">
								<span class="line"></span>
							</div>
							<div class="right">
								<p>{{LeaveDay}}</p>
								<p>连续请假天数</p>
							</div>
						</div>
						<div class="bottom" style="text-align: center;margin-top: 20px;">
							<p style="font-size: 24px;">{{RefundCost}}</p>
							<p>应退金额</p>
						</div>
						<div class="">
							<a style="font-size: 14px;color: #47AFA7;" @click="AttendanceDetails">
								出勤明细
							</a>
						</div>

					</div>
				</div>
			</div>
		</div>
		<!-- 底部学年情况 -->
		<div class="my-card bottomCard">
			<div>
				<span style="font-size: 16px;color: #000000">本学年情况</span>
			</div>
			<div class="middle">
				<div>
					<p>{{mealCostDto.Cost}}</p>
					<p>应付金额</p>
				</div>
				<div>
					<p>{{mealCostDto.LastRefundCost}}</p>
					<p>应退金额</p>
				</div>
				<div>
					<p>{{mealCostDto.PaidInAmount}}</p>
					<p>实收金额</p>
				</div>
			</div>
			<!-- 应退金额明细 -->
			<div class="" style="margin-top: 20px;">
				<a style="font-size: 14px;color: #47AFA7;" @click="show = true">
					应付金额明细
				</a>
			</div>
		</div>
		<selcetMouth ref="selcetMouth" @timeChange='timeChange'></selcetMouth>
		<van-action-sheet v-model="show" title="账单明细">
			<div class="content" v-if="mealList.length > 0">
				<div v-for="item in mealList" :key="item.ID" class="list">
					<div class="left">
						{{typeof(item.month) == 'string'? item.month:item.month + '月' }}
					</div>
					<div class="right">
						<span style="margin-right: 10px;">退费金额</span>{{item.cost + "元"}}
					</div>
				</div>
			</div>
			<!-- 暂无数据 -->
			<div class="" v-else>
				<van-empty description="暂无数据" />
			</div>
		</van-action-sheet>
	</div>
</template>

<script>
	import {
		Image as VanImage,
		Tab,
		Tabs,
		Calendar,
		Icon,
		Toast,
		ActionSheet,
		Empty
	} from 'vant'

	import selcetMouth from '@/components/common/selectMouth/index.vue'

	export default {
		data() {
			return {
				ChildrenName: JSON.parse(window.localStorage.getItem('userInfo')).OpenChildren?.ChildrenName,
				ProfilePicture: JSON.parse(window.localStorage.getItem('userInfo')).OpenChildren?.ProfilePicture,
				mouthList: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
				currentIndex: Number(new Date().getMonth()),
				mouthDay: '', //选中的月份
				AttendanceDay: 0, //出勤天数
				LeaveDay: 0, //连续请假天数
				RefundCost: 0, //应退金额
				year: '',
				mealCostDto: {
					Cost: 0,
					LastRefundCost: 0,
					PaidInAmount: 0,
				},
				show: false,
				mealList: [],
				ChildrenId: JSON.parse(window.localStorage.getItem('userInfo')).OpenChildren?.ID,
			}
		},
		components: {
			selcetMouth,
			[VanImage.name]: VanImage,
			[Tab.name]: Tab,
			[Tabs.name]: Tabs,
			[Calendar.name]: Calendar,
			[Icon.name]: Icon,
			[Toast.name]: Toast,
			[ActionSheet.name]: ActionSheet,
			[Empty.name]: Empty
		},
		mounted() {
			this._mouthDate()
			this._getAttenDanceInfo()
			this.costDetails()
		},
		methods: {
			// 出勤数据
			_getAttenDanceInfo() {
				let model = {
					year: this.year,
					month: this.currentIndex + 1
				}
				this.$axios.get('/api/MealCost/ChildrenMealCost',
					model
				).then(res => {
					if (res.code == 200) {
						//this.ChildrenId = res.data.ChildrenId
						const $attendanceDto = res.data.attendanceDto
						this.AttendanceDay = $attendanceDto.AttendanceDay
						this.LeaveDay = $attendanceDto.LeaveDay
						this.RefundCost = $attendanceDto.RefundCost
						this.mealCostDto = res.data.mealCostDto
					} else {
						this.mealCostDto = {
							Cost: 0,
							LastRefundCost: 0,
							PaidInAmount: 0,
						}
						this.$toast.fail(res.msg || '操作失败')
					}
				})
			},
			// 账单明细
			costDetails() {
				let model = {
					childrenID: this.ChildrenId
				}
				this.$axios.get('/api/MealCost/DetailList',
					model
				).then(res => {
					if (res.code == 200) {
						this.mealList = res.data
					} else {
						this.$toast.fail(res.msg || '操作失败')
					}
				})

			},
			// 跳转到出勤明细
			AttendanceDetails() {
				this.$router.push({
					path: '/parentAttendance',
					query: {
						time: {
							year: this.year,
							mouthDay: this.currentIndex + 1,
						}
					}
				})
			},
			// 选择年月
			openMouth() {
				this.$refs.selcetMouth.show = true
			},
			// 改变年月
			timeChange(time) {
				this.year = time.slice(0, 4)
				this.mouthDay = Number(time.slice(5)) + '月'
				this.currentIndex = Number(time.slice(5) - 1)
				this._getAttenDanceInfo()
			},
			// 选中某月
			tabsclick(index) {
				this.currentIndex = index
				this.mouthDay = index + 1 + '月'
				this._getAttenDanceInfo()
			},
			// 月数据(默认当前月)
			_mouthDate() {
				this.year = new Date().getFullYear()
				this.mouthDay = new Date().getMonth() + 1 + '月'
			},
		}
	}
</script>

<style lang="less" scoped>
	.mealCost {
		padding: 10px 12px;

		.content {
			padding: 16px 16px 30px;

			.list {
				border-bottom: 1px solid #ebedf0;
				display: flex;
				justify-content: space-between;
				padding: 6px 2px;
			}
		}

		.topCard {
			height: 380px;

			.top {
				display: flex;
				align-items: center;
				justify-content: space-between;
				border-bottom: 1px solid #ebedf0;
				padding-bottom: 10px;

				.left {
					display: flex;
					align-items: center;
				}
			}
		}

		.calendarInfo {
			margin-top: 20px;
			width: 100%;
			height: 60%;

			.attendceDay {
				margin-top: 20px;

				.topDay {
					display: flex;
					justify-content: space-around;
					border-bottom: 1px solid #ebedf0;
					padding-bottom: 10px;

					.left,
					.right {
						width: 40%;
					}

					div {
						text-align: center;
						justify-content: space-between;

						.line {
							display: inline-block;
							width: 1px;
							height: 50px;
							background-color: #ebedf0;
							margin: 0 8px;
							position: relative;
							bottom: 1px;
							left: 1px;
						}

						p:first-child {
							font-size: 24px;
						}

						p:last-child {
							margin-top: 4px;
						}
					}
				}
			}

			.mouthDay {
				display: flex;
				flex-wrap: wrap;

				.box {
					min-width: 25px;
					height: 25px;
					padding: 10px;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 50%;
				}

				.active {
					background-color: #47AFA7;
				}
			}

			.date {
				height: 15px;
				position: relative;
				line-height: 15px;
				display: flex;
				align-items: center;

				.line {
					display: inline-block;
					width: 1.3px;
					height: 12px;
					background-color: #ebedf0;
					margin: 0 8px;
					position: relative;
					bottom: 1px;
					left: 1px;
				}

				span {
					font-size: 16px;
				}
			}
		}

		.bottomCard {
			height: 180px;

			.middle {
				display: flex;
				justify-content: space-around;
				margin-top: 20px;

				div {
					text-align: center;
					justify-content: space-between;

					p:first-child {
						font-size: 24px;
					}

					p:last-child {
						margin-top: 4px;
					}
				}
			}
		}
	}
</style>
